<i18n lang="yaml">
pt:
  emptyValue: 'Vazio'
  findOption: 'Procurar opção'
  notFound: 'Não encontrado'
  seeMore: 'Ver mais'
  seeMoreListTitle: Opções selecionadas
  notFoundItem: '(Item não encontrado)'
en:
  emptyValue: 'Empty'
  findOption: 'Find an option'
  notFound: 'Not found'
  seeMore: 'See more'
  seeMoreListTitle: Selected options
  notFoundItem: '(Item not found)'
</i18n>

<template>
  <div class="w-full">
    <v-menu
      v-model="isSeeMoreOpen"
      :close-on-content-click="false"
      persistent
      :max-width="400"
      location="bottom"
    >
      <template #activator="{ props: { onClick: onClickActivator } }">
        <div
          class="deck-select-input"
          :class="{
            'deck-select-input--compact': compact,
          }"
          :style="cssProps"
        >
          <v-autocomplete
            ref="autocomplete"
            v-model="selectedValues"
            v-model:search="search"
            :class="{
              'deck-select-input__autocomplete': true,
              'deck-select-input__autocomplete--truncate': quantity === 1,
              'deck-select-input__autocomplete--align-content-with-outline': outlined,
              'deck-select-input__autocomplete--align-content-with-label': label,
              'deck-select-input__autocomplete--without-padding': !editable,
            }"
            :hide-details="hideDetails"
            :menu-icon="null"
            bottom
            :loading="isLoading"
            hide-selected
            offset-y
            return-object
            :items="itemsIncludingSelectedValues"
            :variant="outlined ? 'outlined' : 'underlined'"
            :multiple="multiple"
            item-title="text"
            item-value="value"
            :menu-props="{
              rounded: true,
              disabled: !useAutocomplete,
              ...menuProps
            }"
            :rules="rules"
            :persistent-hint="(hint && hint.length > 0) || persistentHint"
            :hint="hint"
            :label="label"
            :disabled="!clickable"
            :readonly="!editable"
            :density="dense ? 'compact' : undefined"
            :menu="isAutocompleteOpen"
            @update:menu="onMenuChanges"
            @focus="$emit('focus')"
          >
            <template #prepend-item>
              <v-list-item>
                <deck-text-field
                  ref="search"
                  v-model="search"
                  color="grey lighten"
                  :placeholder="t('findOption')"
                  hide-details
                  @keydown.esc="closeAutocompleteOnEscape"
                />
              </v-list-item>
            </template>

            <template #no-data>
              <v-list-item>
                <v-progress-linear
                  v-if="isLoading"
                  indeterminate
                  rounded
                />
                <v-list-item-title v-else>
                  {{ t('notFound') }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template #item="{ item: { raw: item }, props }">
              <v-list-item v-bind="_omit(props, ['title'])">
                <DeckSelectInputItem
                  :value="item"
                  avoid-truncation
                />
              </v-list-item>
            </template>

            <template #selection="{ item: { raw: item }, index }">
              <DeckSelectInputItem
                v-if="index < quantity"
                :key="`${item.value}-${uuid}`"
                class="deck-select-input__item"
                :value="item"
                :can-remove="editable"
                v-bind="generateDeckSelectInputItemListeners(item)"
              />
              <template v-if="(multiple && index === selectedValues.length - 1) || (!multiple && index === 0 && !_isEmpty(selectedValues))">
                <v-chip
                  v-if="multiple"
                  v-show="hiddenCount > 0"
                  ref="seeMore"
                  class="deck-select-input__see-more deck-select-input__item deck-select-input__item--no-shrink"
                  :aria-label="t('seeMore')"
                  role="button"
                  label
                  @mousedown.stop="onClickActivator"
                >
                  <span
                    v-if="displayHiddenCount"
                    :title="`+${hiddenCount}`"
                  >
                    {{ `+${hiddenCount}` }}
                  </span>
                  <deck-icon
                    v-else
                    size="x-small"
                    name="fa-ellipsis"
                  />
                </v-chip><v-chip
                  v-if="editable"
                  v-show="displayAddItems"
                  ref="add"
                  class="deck-select-input__add-item deck-select-input__item deck-select-input__item--no-shrink"
                  :aria-label="!multiple && !_isEmpty(selectedValues) ? $t('global.change') : $t('global.add')"
                  :title="!multiple && !_isEmpty(selectedValues) ? $t('global.change') : $t('global.add')"
                  role="button"
                  label
                  @click.stop.prevent="openAutocomplete"
                >
                  <deck-icon
                    v-if="!multiple && !_isEmpty(selectedValues)"
                    size="x-small"
                    name="pencil"
                  />
                  <deck-icon
                    v-else
                    size="x-small"
                    name="plus"
                  />
                </v-chip>
              </template>
            </template>

            <template
              v-if="_isEmpty(selectedValues)"
              #prepend-inner
            >
              <v-chip
                v-if="editable"
                v-show="displayAddItems"
                ref="add"
                class="deck-select-input__add-item deck-select-input__item deck-select-input__item--no-shrink deck-select-input__add-item--spaced"
                :aria-label="!multiple && !_isEmpty(selectedValues) ? $t('global.change') : $t('global.add')"
                :title="!multiple && !_isEmpty(selectedValues) ? $t('global.change') : $t('global.add')"
                role="button"
                label
                @click.stop.prevent="openAutocomplete"
              >
                <v-icon size="x-small">
                  fa-plus fa-solid
                </v-icon>
              </v-chip>
            </template>

            <template
              v-if="canCreate && search"
              #append-item
            >
              <DeckSelectInputNewItem
                :value="search"
                @click="$emit('itemCreate', search)"
              />
            </template>
          </v-autocomplete>
        </div>
      </template>

      <v-card
        v-if="multiple"
        role="dialog"
        :aria-label="t('seeMoreListTitle')"
      >
        <v-card-text class="d-flex align-start">
          <div class="deck-select-input__see-more-list">
            <DeckSelectInputItem
              v-for="item in selectedValues"
              :key="`deck-select-input-see-more-${item.value}`"
              :value="item"
              :can-remove="editable"
              class="deck-select-input__item"
              v-bind="generateDeckSelectInputItemListeners(item)"
            />
          </div>
          <v-icon
            class="deck-select-input__see-more-list__close-button"
            :aria-label="$t('global.close')"
            :title="$t('global.close')"
            size="x-small"
            @click="isSeeMoreOpen = false"
          >
            far fa-close
          </v-icon>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { everyItemOfArrayShouldHave } from 'vue-prop-validation-helper';
import { INTER_FONT_WIDEST_WIDTH_BY_SIZE } from '~/assets/javascript/constants';
import { v4 as uuidv4 } from 'uuid';

const CHIP_FONT_SIZE = 14;
const CHIP_PADDING = 8;
const CHIP_MARGIN = 2;
const HORIZONTAL_PADDINGS = CHIP_PADDING * 2;
const HORIZONTAL_MARGINS = CHIP_MARGIN * 2;

const calculateItemSize = (text) => {
  const textSize = (text || '').length * INTER_FONT_WIDEST_WIDTH_BY_SIZE[CHIP_FONT_SIZE];
  return textSize + HORIZONTAL_PADDINGS + HORIZONTAL_MARGINS;
};

const SORT_VALUES_MAPPING = {
  asc: 'asc',
  desc: 'desc',
  false: null, // don't sort
  true: 'asc',
};

export default {
  name: 'DeckSelectInput',
  components: {
    DeckSelectInputItem: defineAsyncComponent(() => import('./_item')),
    DeckSelectInputNewItem: defineAsyncComponent(() => import('./_new-item')),
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
      validator: (value) => {
        if (!value) {
          return false;
        }

        // checks if every item from value is a string
        if (value.every(item => typeof item === 'string')) {
          return true;
        }

        // otherwise it should be an object with the required keys
        return everyItemOfArrayShouldHave(['text', 'value'])(value);
      },
    },
    items: {
      type: Array,
      default: () => [],
      validate: everyItemOfArrayShouldHave(['text', 'value']),
    },
    editable: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    displayHiddenCount: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    useAutocomplete: {
      type: Boolean,
      default: false,
    },
    displayAddItems: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    label: { type: String, default: null },
    outlined: { type: Boolean, default: false },
    clickable: { type: Boolean, default: true },
    dense: { type: Boolean, default: false },
    /**
     * Whether the items should be sorted. It accepts Boolean and Strings case-insensitive.
     * @type {string | boolean}
     * @default true
     */
    sort: {
      type: [String, Boolean],
      default: true,
    },
    openSeeMoreOnClickableChange: { type: Boolean, default: false },
    hideDetails: { type: [String, Boolean], default: 'auto' },
    menuProps: {
      type: Object,
      default: () => ({}),
    },
    /**
     * The width of the component will be auto, to prevent the component from
     * taking the full width and steal the click events from
     * the parent component in the blank spaces.
     * @type {boolean}
     * @default false
     */
    compact: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'focus',
    'itemCreate',
    'itemAdd',
    'seeMoreOpen',
    'seeMoreClose',
    'autocompleteOpen',
    'autocompleteClose',
    'itemClick',
    'itemRemove',
    'itemAddButtonClick',
    'update:modelValue',
    'update:error',
  ],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  data() {
    return {
      uuid: uuidv4(), // it was needed because the _uid will be deprecated
      resizeObserver: null,
      isAutocompleteOpen: false,
      isSeeMoreOpen: false,
      search: null,
      selectedValues: this.extractSelectedValue(this.modelValue),
      listSize: null,
      updateListSizeMethod: null,
      isValid: true,
    };
  },
  computed: {
    itemClickable() {
      // TODO If we want to check if a custom event was passed, we need to check vnode props
      // because the event is defined at emits
      return Boolean(this.$.vnode.props.onItemClick);
    },
    itemsIncludingSelectedValues() {
      const formattedModelValue = this.modelValue.map(value => (typeof value === 'string' ? ({ text: value, value }) : value));
      const values = _uniqBy([...formattedModelValue, ...this.items], item => item.value);

      if (this.sort) return _orderBy(values, ['text'], [this.calculateSortValue()]);

      return values;
    },
    hiddenCount() {
      if (!this.multiple || this.selectedValues.length === 0) return 0;

      return this.selectedValues.length - this.quantity;
    },
    quantity() {
      if (!this.multiple) return _isEmpty(this.selectedValues) ? 0 : 1;
      if (!this.listSize || this.selectedValues.length === 0) return 0;
      let quantity = 0;

      let remaining = this.listSize;
      const seeMoreSize = calculateItemSize(`+${this.selectedValues.length}`);
      const addSize = this.editable ? calculateItemSize('+') : 0;
      remaining -= seeMoreSize + addSize;

      if (remaining < 0) return 1;

      this.selectedValues.every(({ text }) => {
        const itemSize = calculateItemSize(text);
        if (remaining - itemSize < 0) return false;

        remaining -= itemSize;
        quantity += 1;
        return true;
      });

      return quantity || 1;
    },
    cssProps() {
      return {
        '--deck-select-input-item-margin': `${CHIP_MARGIN}px`,
      };
    },
  },
  watch: {
    modelValue(modelValue) {
      if (_isEqual(this.selectedValues, this.extractSelectedValue(modelValue))) return;
      this.selectedValues = this.extractSelectedValue(modelValue);
    },
    async selectedValues(newValue, oldValue) {
      if (_isEqual(newValue, this.extractSelectedValue(this.modelValue))) return;

      await nextTick();
      const { autocomplete } = this.$refs;
      const validation = await autocomplete.validate();
      const validationMessage = validation.join(', ');
      this.isValid = validationMessage.length === 0;

      if (!this.isValid && this.hideDetails && this.hideDetails !== 'auto') {
        this.$notifier.showMessage({
          content: validationMessage,
          color: 'warning',
        });

        return;
      }

      if (this.multiple) {
        const itemsToAdd = newValue
          .filter(value => !this.modelValue.some(old => old.value === value.value))
          .map(value => value.value);

        itemsToAdd.forEach(value => this.$emit('itemAdd', value));

        const itemsToRemove = this.modelValue
          .filter(value => !newValue.some(old => old.value === value.value))
          .map(value => value.value);

        itemsToRemove.forEach(value => this.$emit('itemRemove', value));
      } else if (newValue?.value) {
        this.$emit('itemAdd', newValue.value);
        document.body.click(); // remove focus to close the menu
      } else {
        this.$emit('itemRemove', oldValue.value);
      }

      this.$emit('update:modelValue', newValue);
    },
    async clickable(value) {
      if (!this.openSeeMoreOnClickableChange || this.hiddenCount <= 0) {
        this.isSeeMoreOpen = false;
        return;
      }

      this.isSeeMoreOpen = value;
    },
    isSeeMoreOpen(value) {
      const { autocomplete } = this.$refs;

      if (value) {
        this.$emit('seeMoreOpen');

        if (autocomplete.isMenuActive) {
          autocomplete.isMenuActive = false;
        }
      } else {
        this.$emit('seeMoreClose');
      }
    },
    isValid(value) {
      this.$emit('update:error', !value);
    },
  },
  async mounted() {
    await nextTick();
    this.configureResizeObserver();
  },
  beforeUnmount() {
    this.removeResizeObserver();
  },
  methods: {
    calculateSortValue() {
      if (typeof this.sort === 'string') return SORT_VALUES_MAPPING[this.sort.toLowerCase()];

      return SORT_VALUES_MAPPING[this.sort];
    },
    onMenuChanges(state) {
      if (!this.useAutocomplete) return;

      this.isAutocompleteOpen = state;
      const action = state ? 'Open' : 'Close';

      this.$emit(`autocomplete${action}`);
    },
    configureResizeObserver() {
      const { autocomplete } = this.$refs;
      if (!autocomplete) return;
      const autocompleteElement = autocomplete.$el;
      this.listSize = autocompleteElement.clientWidth;

      this.updateListSizeMethod = _debounce(() => {
        this.listSize = autocompleteElement.clientWidth;
      }, 200);

      this.resizeObserver = new ResizeObserver(this.updateListSizeMethod);
      this.resizeObserver.observe(autocompleteElement);
    },
    removeResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.unobserve(this.$refs.autocomplete.$el);
        this.resizeObserver = null;
      }
    },
    extractSelectedValue(value) {
      let parsedValue = _cloneDeep(value);

      if (Array.isArray(parsedValue) && parsedValue.every(item => typeof item === 'string')) {
        parsedValue = parsedValue.map(item => (this.items.find(({ value: itemValue }) => itemValue === item)) || { text: this.t('notFoundItem'), value: item });
      }

      const selectedValue = !this.multiple && Array.isArray(parsedValue) ? parsedValue[0] : parsedValue;

      if (this.sort && this.multiple) return _orderBy(selectedValue, ['text'], [this.calculateSortValue()]);

      return selectedValue;
    },
    openAutocomplete() {
      if (this.useAutocomplete) {
        this.isSeeMoreOpen = false;
        this.isAutocompleteOpen = true;
        this.$refs.autocomplete.focus();
      } else {
        this.$emit('itemAddButtonClick');
      }
    },
    generateDeckSelectInputItemListeners(item) {
      const listeners = {};

      if (this.itemClickable) {
        listeners.onClick = (event) => {
          event.stopPropagation();
          event.preventDefault();
          this.$emit('itemClick', item.value);
        };
      }

      if (this.editable) {
        listeners.onRemove = () => {
          if (this.multiple) {
            this.selectedValues = this.selectedValues.filter(selected => selected.value !== item.value);
          } else {
            this.selectedValues = null;
          }
        };
      }

      return listeners;
    },
    closeAutocompleteOnEscape() {
      this.$refs.autocomplete.blur();
    },
  },
};
</script>

<style lang="scss">
.deck-select-input {
  max-width: 100%;
  position: relative;
  width: 100%;
}

.deck-select-input__item {
  border-radius: var(--z-border-radius-inner-base);
  margin: var(--deck-select-input-item-margin) !important;
}

.deck-select-input__item--no-shrink {
  flex-shrink: 0 !important;
}

.deck-select-input__see-more-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--z-s3);
  max-height: 320px;
  overflow: auto;
  width: 100%;
  padding: 8px;
}

.deck-select-input__see-more-list__close-button {
  height: 24px;
  width: 24px;
}

.deck-select-input__see-more {
  height: 24px !important;
  padding-left: 8px;
  padding-right: 8px;

  & > .v-chip__content {
    display: inline-flex;
  }
}

.deck-select-input__add-item {
  align-items: center;
  display: inline-flex;
  height: 24px !important;
  justify-content: center;
  padding: 0 !important;
  width: 24px !important;
}

.deck-select-input__add-item--spaced {
  margin: var(--autocomplete-vertical-padding) var(--autocomplete-horizontal-padding) !important;
}

.deck-select-input__new-item {
  bottom: 0;
  left: 0;
  position: absolute;
}

.deck-select-input__autocomplete {
  --autocomplete-horizontal-padding: var(--z-s3);
  --autocomplete-vertical-padding: calc(var(--z-s3) / 2);

  margin: 0 !important;
  max-width: 100%;
  padding: 0 !important;
  width: 100%;

  .v-field__field {
    display: flex !important;
    align-items: center !important;

    .v-field__input {
      display: flex;
      align-items: center;
      padding: var(--autocomplete-vertical-padding) var(--autocomplete-horizontal-padding) !important;
    }
  }

  .v-input__control {
    width: 100%;
  }

  input {
    overflow: hidden;
    height: 0;
    min-width: unset !important;
    padding: 0 !important;
    width: 0;
  }

  // Remove border
  &.v-text-field > .v-input__control .v-field__outline::before,
  &.v-text-field > .v-input__control .v-field__outline::after {
    border: none;
  }

  & .v-field.v-field--variant-underlined .v-field__prepend-inner {
    align-self: center;
    padding-top: 0;
  }

  .v-input__control {
    height: auto;
  }

  .v-select__slot {
    display: block;
    height: auto;
    width: 100%;
  }

  .v-field, .v-field__input {
    padding-top: 0 !important;
    cursor: unset !important;
  }

  .v-input__append-inner,
  .v-select__selections {
    display: flex;
    flex-wrap: wrap;
    margin-inline: calc(var(--deck-select-input-item-margin) * -1);
    height: auto;
    padding: 0 !important;
  }

  &.deck-select-input__autocomplete--truncate {
    .v-select__selections {
      display: inline-flex;
      flex-wrap: nowrap;
      max-width: 100%;
    }
  }

  &.deck-select-input__autocomplete--align-content-with-outline {
    .v-select__slot {
      display: flex;
      flex-direction: row-reverse;
    }

    .v-label {
      &:not(.v-label--active) {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
      }

      &.v-label--active {
        position: absolute !important;
        left: 2px !important;
        top: 13px !important;
      }
    }
  }

  &.deck-select-input__autocomplete--align-content-with-label {
    .v-select__slot {
      display: flex;
      flex-direction: row;
    }
  }
}

.deck-select-input__autocomplete--without-padding {
  --autocomplete-horizontal-padding: 0;
}
</style>
